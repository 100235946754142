@import url("https://fonts.googleapis.com/css?family=Nunito|Pacifico|Press+Start+2P|Satisfy|VT323&display=swap");
html {
  scroll-behavior: smooth;
}
.nunito {
  font-family: "Nunito", sans-serif;
}
.fontSizeContact {
  font-size: 1.2rem;
}
#tsparticles canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.vertical-timeline.vertical-timeline-custom-line::before {
  background: #424242 !important;
}

.pointer {
  cursor: pointer;
}

.bgAuth {
  background-image: url("https://public-mickaeltordjman.s3.eu-west-3.amazonaws.com/background.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
}
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.fontPolice {
  font-family: "VT323", monospace;
}
.cvImg {
  width: 15vw;
}

.satisfy {
  font-family: "Satisfy", cursive;
}
.white-fade {
  color: rgb(101, 97, 97);
}
.mickamagru {
  font-family: "Pacifico", cursive;
}
.progress-bar {
  background-color: #6e41c6 !important;
}
.white {
  color: white !important;
}
.modal-title {
  font-size: 2.5rem !important;
}
.purple {
  color: #6e41c6 !important;
}
.background-assembly {
  background-image: url("https://public-mickaeltordjman.s3.eu-west-3.amazonaws.com/background.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-purple {
  background-color: #6e41c6 !important;
  border-color: transparent !important;
}
.bg-purple:hover {
  background-image: url("https://public-mickaeltordjman.s3.eu-west-3.amazonaws.com/background.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.hidden {
  visibility: hidden !important;
}

.white {
  color: white;
}
.icon42 {
  width: 1.5vw;
}
.icon42_pro {
  width: 2.5vw;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .cvImg {
    width: 50vw;
  }
  .icon42 {
    width: 5vw;
  }
  .icon42_pro {
    width: 7vw;
  }
}

.pdf {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  margin: 20px;
}

.contact-icon {
  width: 64px;
  height: 64px;
  margin-right: 16px;
}

.icon-container {
  display: flex;
  align-items: center;
}

.fontSizeContact {
  font-size: 1.2rem;
}
